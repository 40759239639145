var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.modules_vue.length
    ? _c("section", { staticClass: "section-modules", class: _vm.prop_class }, [
        _c("div", { staticClass: "modules-wrap container" }, [
          _c("h2", { staticClass: "text-center page-h2" }, [
            _vm._v(_vm._s(this.prop_title))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-center text-grey" }, [
            _c("strong", [_vm._v(_vm._s(this.prop_highlighted_description))]),
            _vm._v(
              "\n            " + _vm._s(this.prop_description) + "\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modules-list d-flex flex-wrap" },
            _vm._l(_vm.modules_vue, function(module, index) {
              return _c(
                "div",
                {
                  staticClass: "card",
                  class: _vm.prop_colors[_vm.counterAction(_vm.counter)],
                  style: _vm.styleCard(module.card_color),
                  on: {
                    click: function($event) {
                      return _vm.redirectToDetails(
                        module.card_link_to_detail_page
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          module.anchor_card_link_to_detail_page
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-title" },
                      [
                        module.single_catagery_name
                          ? _vm._t("default", [
                              _vm._v(_vm._s(module.single_catagery_name))
                            ])
                          : _vm._t("default", [_vm._v(" ")])
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-text" }, [
                      _c("div", { staticClass: "module-title" }, [
                        _c("h3", { staticClass: "mb-4" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(module.title) +
                              "\n                            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(module.duration) +
                            "\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("h3", { staticClass: "cost" }, [
                        _vm._v("€ " + _vm._s(module.custom_price_format))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "divider my-4" }),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "termine" },
                        [
                          _vm.prop_module_type === "curriculum"
                            ? _vm._t("default", [
                                _vm._v("Starttermine:"),
                                _c("br")
                              ])
                            : _vm._t("default", [_vm._v("Termine:"), _c("br")]),
                          _vm._v(" "),
                          _vm._l(module.cities_ids, function(city_id, index) {
                            return _vm._t("default", [
                              index >= 2 ? _vm._t("default") : _vm._e()
                            ])
                          }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                module.nearest_event_location_card_info
                              )
                            }
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
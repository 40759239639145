<template>
    <section class="section-modules" :class="prop_class" v-if="modules_vue.length">
        <div class="modules-wrap container">
            <h2 class="text-center page-h2">{{ this.prop_title }}</h2>
            <p class="text-center text-grey">
                <strong>{{ this.prop_highlighted_description }}</strong>
                {{ this.prop_description }}
            </p>
            <div class="modules-list d-flex flex-wrap">

                <div v-for  = "(module, index) in modules_vue"
                     class  = "card" :class="prop_colors[counterAction(counter)]"
                     :style = "styleCard(module.card_color)"
                     v-on:click="redirectToDetails(module.card_link_to_detail_page)"
                >
                    <div class="card-body">
                        <span v-html="module.anchor_card_link_to_detail_page"></span>

                        <div class="card-title">
                            <slot v-if="module.single_catagery_name">{{ module.single_catagery_name }}</slot>
                            <slot v-else>&nbsp;</slot><!-- Show empty white line -->
                        </div>

                        <div class="card-text">
                            <div class="module-title">
                                <h3 class="mb-4">
                                    {{ module.title }}
                                </h3>
                            </div>

                            <span>
                                {{ module.duration }}
                            </span>

                            <h3 class="cost">€ {{ module.custom_price_format }}</h3>

                            <div class="divider my-4"></div>

                            <p class="termine">
                                <slot v-if="prop_module_type === 'curriculum'">Starttermine:<br></slot>
                                <slot v-else>Termine:<br></slot>

                                <slot v-for="(city_id, index) in module.cities_ids">
                                    <slot v-if="index >= 2"></slot>
                                </slot>

                                <span v-html="module.nearest_event_location_card_info"></span>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { store } from "../store/filter";

    export default {
        props: {
            prop_class: {
                type: String,
                required: false
            },
            prop_colors: {
                type: [Array],
                required: false
            },
            prop_module_type: {
                type: String,
                required: false
            },
            prop_title: {
                type: String,
                required: false
            },
            prop_highlighted_description: {
                type: String,
                required: false
            },
            prop_description: {
                type: String,
                required: false
            },
        },

        data(){
            return {
                counter : 0,
            }
        },

        computed: {
            modules_vue() {
                if(this.prop_module_type === 'curriculum') {
                    return _.orderBy(store.filter.curriculums, 'position');
                } else {
                    return _.orderBy(store.filter.workshops, 'position');
                }
            },
        },

        methods: {
            styleCard(custom_card_color) {
                return 'background-color: ' + custom_card_color;
            },
            counterAction(current_counter) {
                return current_counter = (this.counter === 3) ? 0 : this.counter++;
            },
            redirectToDetails(link) {
                window.location.href = link;
            }
        },
    }
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "section-filter" }, [
        _c(
          "div",
          {
            staticClass:
              "filter-wrap container d-md-flex flex-md-row justify-content-between align-items-center d-flex flex-column"
          },
          [
            _c(
              "button",
              {
                staticClass: "button button-secondary my-2 my-md-0",
                on: { click: _vm.reset }
              },
              [_vm._v("Reset filter")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-group btn-group-filter my-2 my-md-0" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selected_curriculums,
                        expression: "selected_curriculums"
                      }
                    ],
                    staticClass: "browser-default custom-select",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selected_curriculums = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.onChange()
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { disabled: "" },
                        domProps: { value: undefined }
                      },
                      [_vm._v("Curricula")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.curriculums, function(curriculum, index) {
                      return _c(
                        "option",
                        {
                          domProps: {
                            value: {
                              slug: curriculum.slug,
                              title: curriculum.title
                            }
                          }
                        },
                        [_vm._v(_vm._s(curriculum.title))]
                      )
                    })
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-group btn-group-filter my-2 my-md-0" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selected_categories,
                        expression: "selected_categories"
                      }
                    ],
                    staticClass: "browser-default custom-select",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selected_categories = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.onChange()
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { disabled: "" },
                        domProps: { value: undefined }
                      },
                      [_vm._v("Kategorien")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.categories, function(category, index) {
                      return _c(
                        "option",
                        {
                          domProps: {
                            value: {
                              id: category.id,
                              name: category.name
                            }
                          }
                        },
                        [_vm._v(_vm._s(category.name))]
                      )
                    })
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-group btn-group-filter my-2 my-md-0" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selected_lecturers,
                        expression: "selected_lecturers"
                      }
                    ],
                    staticClass: "browser-default custom-select",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selected_lecturers = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.onChange()
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { disabled: "" },
                        domProps: { value: undefined }
                      },
                      [_vm._v("Referenten")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.lecturers, function(lecturer, index) {
                      return _c(
                        "option",
                        {
                          domProps: {
                            value: {
                              slug: lecturer.slug,
                              firstname: lecturer.firstname,
                              lastname: lecturer.lastname
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(lecturer.firstname) +
                              " " +
                              _vm._s(lecturer.lastname)
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-group btn-group-filter my-2 my-md-0" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selected_locations,
                        expression: "selected_locations"
                      }
                    ],
                    staticClass: "browser-default custom-select",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selected_locations = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.onChange()
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { disabled: "" },
                        domProps: { value: undefined }
                      },
                      [_vm._v("Standorte")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.locations, function(location, index) {
                      return _c(
                        "option",
                        {
                          domProps: {
                            value: {
                              id: location.id,
                              location: location.location
                            }
                          }
                        },
                        [_vm._v(_vm._s(location.location))]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.info_block_flag
          ? _c("section", { staticClass: "py-4 breadcrumbs-block" }, [
              _c("div", { staticClass: "modules-wrap container" }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _vm._v("Aktive Filter  |\n                    "),
                    typeof this.selected_curriculums === "object"
                      ? _vm._t("default", [
                          _c("strong", [_vm._v(" Curricula: ")]),
                          _vm._v(_vm._s(_vm.selected_curriculums.title)),
                          _c(
                            "span",
                            {
                              staticClass: "btn-circle",
                              on: {
                                click: function($event) {
                                  return _vm.resetPartially({
                                    type: "curriculums"
                                  })
                                }
                              }
                            },
                            [_vm._v("X")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    typeof this.selected_categories === "object"
                      ? _vm._t("default", [
                          _c("strong", [_vm._v(" Kategorien: ")]),
                          _vm._v(_vm._s(_vm.selected_categories.name)),
                          _c(
                            "span",
                            {
                              staticClass: "btn-circle",
                              on: {
                                click: function($event) {
                                  return _vm.resetPartially({
                                    type: "categories"
                                  })
                                }
                              }
                            },
                            [_vm._v("X")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    typeof this.selected_lecturers === "object"
                      ? _vm._t("default", [
                          _c("strong", [_vm._v(" Referenten: ")]),
                          _vm._v(
                            _vm._s(_vm.selected_lecturers.firstname) +
                              " " +
                              _vm._s(_vm.selected_lecturers.lastname)
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "btn-circle",
                              on: {
                                click: function($event) {
                                  return _vm.resetPartially({
                                    type: "lecturers"
                                  })
                                }
                              }
                            },
                            [_vm._v("X")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    typeof this.selected_locations === "object"
                      ? _vm._t("default", [
                          _c("strong", [_vm._v(" Standorte: ")]),
                          _vm._v(_vm._s(_vm.selected_locations.location)),
                          _c(
                            "span",
                            {
                              staticClass: "btn-circle",
                              on: {
                                click: function($event) {
                                  return _vm.resetPartially({
                                    type: "locations"
                                  })
                                }
                              }
                            },
                            [_vm._v("X")]
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.empty_result_flag
        ? _c("section", { staticClass: "my-4 not-result-block" }, [_vm._m(0)])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modules-wrap container" }, [
      _c("h3", { staticClass: "text-center" }, [
        _vm._v("Diese Kombination führt zu keinem Ergebnis.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <section class="section-filter">
            <div class="filter-wrap container d-md-flex flex-md-row justify-content-between align-items-center d-flex flex-column">

                <button v-on:click="reset" class="button button-secondary my-2 my-md-0">Reset filter</button>

                <div class="btn-group btn-group-filter my-2 my-md-0">
                    <select v-model="selected_curriculums" class="browser-default custom-select" @change="onChange()">
                        <option :value="undefined" disabled>Curricula</option>
                        <option v-for="(curriculum, index) in curriculums"
                                v-bind:value="{
                                    slug  : curriculum.slug,
                                    title : curriculum.title,
                                }"
                        >{{ curriculum.title }}</option>
                    </select>
                </div>

                <div class="btn-group btn-group-filter my-2 my-md-0">
                    <select v-model="selected_categories" class="browser-default custom-select" @change="onChange()">
                        <option :value="undefined" disabled>Kategorien</option>
                        <option v-for="(category, index) in categories"
                                v-bind:value="{
                                    id  : category.id,
                                    name : category.name,
                                }"
                        >{{ category.name }}</option>
                    </select>
                </div>

                <div class="btn-group btn-group-filter my-2 my-md-0">
                    <select v-model="selected_lecturers" class="browser-default custom-select" @change="onChange()">
                        <option :value="undefined" disabled>Referenten</option>
                        <option v-for="(lecturer, index) in lecturers"
                                v-bind:value="{
                                    slug  : lecturer.slug,
                                    firstname : lecturer.firstname ,
                                    lastname : lecturer.lastname,
                                }"
                        >{{ lecturer.firstname }} {{ lecturer.lastname }}</option>
                    </select>
                </div>

                <div class="btn-group btn-group-filter my-2 my-md-0">
                    <select v-model="selected_locations" class="browser-default custom-select" @change="onChange()">
                        <option :value="undefined" disabled>Standorte</option>
                        <option v-for="(location, index) in locations"
                                v-bind:value="{
                                    id  : location.id,
                                    location : location.location,
                                }"
                        >{{ location.location }}</option>
                    </select>
                </div>

            </div>
        </section>

        <transition name="fade">
            <section class="py-4 breadcrumbs-block" v-if="info_block_flag">
                <div class="modules-wrap container">
                    <div class="text-center">Aktive Filter &nbsp;|
                        <slot v-if="typeof this.selected_curriculums === 'object'"><strong>&nbsp;Curricula: </strong>{{ selected_curriculums.title }}<span class="btn-circle" @click="resetPartially({type : 'curriculums'})">X</span></slot>
                        <slot v-if="typeof this.selected_categories === 'object'"><strong>&nbsp;Kategorien: </strong>{{ selected_categories.name }}<span class="btn-circle" @click="resetPartially({type : 'categories'})">X</span></slot>
                        <slot v-if="typeof this.selected_lecturers === 'object'"><strong>&nbsp;Referenten: </strong>{{ selected_lecturers.firstname }} {{ selected_lecturers.lastname }}<span class="btn-circle" @click="resetPartially({type : 'lecturers'})">X</span></slot>
                        <slot v-if="typeof this.selected_locations === 'object'"><strong>&nbsp;Standorte: </strong>{{ selected_locations.location }}<span class="btn-circle" @click="resetPartially({type : 'locations'})">X</span></slot>
                    </div>
                </div>
            </section>
        </transition>

        <section class="my-4 not-result-block" v-if="empty_result_flag">
            <div class="modules-wrap container">
                <h3 class="text-center">Diese Kombination führt zu keinem Ergebnis.</h3>
            </div>
        </section>

    </div>
</template>

<script>
    import { store, mutations } from "../store/filter";

    export default {
        data(){
            return {
                selected_curriculums : 'undefined',
                selected_workshops : 'undefined',
                selected_lecturers : 'undefined',
                selected_locations : 'undefined',
                selected_categories : 'undefined',
                empty_result_flag  : false,
                info_block_flag    : false,
            }
        },

        computed: {
            curriculums() {
                if(typeof store.filter.curriculums !== 'undefined' && !Object.keys(store.filter.curriculums).length) {
                    if(this.selected_curriculums === 'undefined') {
                        return [];
                    } else {
                        return [this.selected_curriculums];
                    }
                }
                return _.orderBy(store.filter.curriculums, 'position');
            },
            workshops() {
                if(typeof store.filter.workshops !== 'undefined' &&  !store.filter.workshops.length) {
                    if(this.selected_workshops === 'undefined') {
                        return [];
                    } else {
                        return [this.selected_workshops];
                    }
                }
                return _.orderBy(store.filter.workshops, 'position');
            },
            lecturers() {
                if(typeof store.filter.lecturers !== 'undefined' && !store.filter.lecturers.length) {
                    if(this.selected_lecturers === 'undefined') {
                        return [];
                    } else {
                        return [this.selected_lecturers];
                    }
                }
                return _.orderBy(store.filter.lecturers, 'firstname');
            },
            locations() {
                if(typeof store.filter.locations !== 'undefined' && !store.filter.locations.length) {
                    if(this.selected_locations === 'undefined') {
                        return [];
                    } else {
                        return [this.selected_locations];
                    }
                }
                return _.orderBy(store.filter.locations, 'position');
            },
            categories() {
                if(typeof store.filter.categories !== 'undefined' && !store.filter.categories.length) {
                    if(this.selected_categories === 'undefined') {
                        return [];
                    } else {
                        return [this.selected_categories];
                    }
                }
                return _.orderBy(store.filter.categories, 'name');
            }
        },

        methods: {
            async onChange() {
                let data_to_server = {
                    'curriculum' : typeof this.selected_curriculums === "string" ? this.selected_curriculums : this.selected_curriculums.slug,
                    'workshop'   : typeof this.selected_workshops === "string" ? this.selected_workshops : this.selected_workshops.slug,
                    'lecturer'   : typeof this.selected_lecturers === "string" ? this.selected_lecturers : this.selected_lecturers.slug,
                    'location'   : typeof this.selected_locations === "string" ? this.selected_locations : this.selected_locations.id,
                    'category'   : typeof this.selected_categories === "string" ? this.selected_categories : this.selected_categories.id,
                };
                await mutations.getSearchFilters(data_to_server);
                if(
                    typeof this.selected_curriculums === "object" ||
                    typeof this.selected_workshops === "object" ||
                    typeof this.selected_lecturers === "object" ||
                    typeof this.selected_locations === "object" ||
                    typeof this.selected_categories === "object"
                )
                {
                    this.info_block_flag = true;
                }

                this.$nextTick(function () {
                    this.empty_result_flag = (!Object.keys(store.filter.curriculums).length && !store.filter.workshops.length) ? true : false;
                });
            },

            reset() {
                mutations.getAllFilters();
                this.selected_curriculums = 'undefined';
                this.selected_workshops = 'undefined';
                this.selected_lecturers = 'undefined';
                this.selected_locations = 'undefined';
                this.selected_categories = 'undefined';
                this.info_block_flag = false;
                this.empty_result_flag = false;
            },

            resetPartially(obj_reset) {
                switch(obj_reset.type) {
                    case 'curriculums':
                        this.selected_curriculums = 'undefined';
                        break;
                    case 'workshops':
                        this.selected_workshops = 'undefined';
                        break;
                    case 'lecturers':
                        this.selected_lecturers = 'undefined';
                        break;
                    case 'locations':
                        this.selected_locations = 'undefined';
                        break;
                    case 'categories':
                        this.selected_categories = 'undefined';
                        break;
                    default: break;
                }
                this.checkCloseBreadcrumbs();
            },

            checkCloseBreadcrumbs() {
                if(this.selected_curriculums === 'undefined' &&
                    this.selected_workshops === 'undefined' &&
                    this.selected_lecturers === 'undefined' &&
                    this.selected_locations === 'undefined' &&
                    this.selected_categories === 'undefined')
                {
                    this.reset();
                } else {
                    this.onChange();
                }
            }
        },

        mounted() {
            mutations.getAllFilters();
        },
    }
</script>

<style>
    .not-result-block h3 {
        margin-bottom: 0;
    }
    .breadcrumbs-block {
        background-color: rgba(0,0,0,0.14);
        color: #666;
        line-height: 1.7em;
        font-weight: 500;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .7s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .btn-circle {
        margin: 0 5px;
        padding: 0 5px;
        background-color: #666;
        border: 1px solid #666;
        border-radius: 10px;
        cursor: pointer;
        color: white;
    }

    @media screen and (min-width: 768px) {
        .btn-group-filter {
            max-width: 15%;
        }
    }
</style>
